import { config } from '@abyss/web/tools/config';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../common/Utils/getFeatureFlag';
import {
  useEducationalContentStatus,
  useSaveEducationalContentStatus,
} from '../../../../hooks/useEducationalContent';
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  educationalContentModalInfoStyles,
  educationalContentModalStyles,
} from './EducationalContent.styled';

export const EducationalContentModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const featureFlags = useLagoon('feature-flags')();
  const [, setEducationalContentStatus] = useSaveEducationalContentStatus({});
  const data = useLagoon('ui-messaging')();
  const educationalContentModalTitle = find(data, {
    key: ConstantsLagoon.EDUCATIONAL_CONTENT_MODAL
      .EDUCATIONAL_CONTENT_MODAL_TITLE,
  });
  const educationalContentModalDescription = find(data, {
    key: ConstantsLagoon.EDUCATIONAL_CONTENT_MODAL
      .EDUCATIONAL_CONTENT_MODAL_DESCRIPTION,
  });
  const educationalContentStatusArgs = {
    hasViewedContent: true,
  };
  const [educationalContent, getEducationalContentStatus] =
    useEducationalContentStatus({});

  const educationalContentStatus =
    educationalContent?.data?.educationalContentStatus;

  const introCreatedDate: Date = new Date(
    educationalContentStatus?.createdDate
  );
  const todayDate: Date = new Date();
  const timeDiff = todayDate.getTime() - introCreatedDate.getTime();
  const memberLoginPeriod: number = Math.floor(
    timeDiff / (1000 * 60 * 60 * 24)
  );
  const hasViewedIntroVideo =
    memberLoginPeriod > 180 && educationalContentStatus?.createdDate !== null
      ? true
      : educationalContentStatus?.hasViewedContent;

  useEffect(() => {
    if (
      !educationalContentStatus?.hasViewedContent &&
      memberLoginPeriod > 180 &&
      educationalContentStatus?.createdDate !== null
    ) {
      setEducationalContentStatus({
        variables: educationalContentStatusArgs,
      });
    }
  }, [hasViewedIntroVideo]);

  useEffect(() => {
    getEducationalContentStatus();
  }, []);

  const lastUpdatedDate = new Date(educationalContentStatus?.lastUpdatedDate);
  const formatedCurrentDate = new Date().toISOString().slice(0, 10);

  const modalIsDisplayedToday = () =>
    lastUpdatedDate.toISOString().slice(0, 10) === formatedCurrentDate;

  const educationalContentVariables = {
    hasViewedContent: hasViewedIntroVideo,
  };

  const educationContenEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.EDUCATIONAL_CONTENT_ENABLED
  );

  if (
    educationContenEnabled &&
    hasViewedIntroVideo === false &&
    !modalIsDisplayedToday()
  ) {
    return (
      <React.Fragment>
        <Modal
          css={educationalContentModalStyles}
          data-auto-testid="educational-content-modal"
          data-testid="educational-content-modal"
          isOpen={isOpen}
          onClose={() => {
            setEducationalContentStatus({
              variables: educationalContentVariables,
            });
            setIsOpen(false);
          }}
          title={educationalContentModalTitle?.message}
        >
          <Modal.Section css={educationalContentModalInfoStyles}>
            <video
              controls
              crossOrigin="anonymous"
              data-auto-testid="educational-content-video"
              data-testid="educational-content-video"
              height="auto"
              onEnded={() => setIsOpen(false)}
              onPlay={() =>
                setEducationalContentStatus({
                  variables: educationalContentStatusArgs,
                })
              }
              width="100%"
            >
              <source
                src={`${config('CDN_BASE_URL')}/cdn/assets/UHC_PSX.mp4`}
                type="video/mp4"
              />
              <track
                kind="captions"
                label="English"
                src={`${config(
                  'CDN_BASE_URL'
                )}/cdn/assets/UHC_PSX_Video.en_US.vtt`}
                srcLang="en"
              />
            </video>
          </Modal.Section>
          <Modal.Section
            css={educationalContentModalInfoStyles}
            data-auto-testid="educational-content-info"
            data-testid="educational-content-info"
          >
            {educationalContentModalDescription?.message}
          </Modal.Section>
        </Modal>
      </React.Fragment>
    );
  }
  return null;
};
